
import { modulo } from '@/assets/scripts/utils'

const MIN_ITEM_COUNT = 4

function removeSlash(str) { return str.replaceAll('/', '') }
function normalizeUrl(str) {
  return str.endsWith('/') ? str : `${str}/`;
}

export default {
  name: 'PopinNavigation',

  props: {
    items: { type: Array, default: () => [] },
  },

  data() {
    return {
      currentIndex: 0,
      offset: 0,
      showItems: false,
    }
  },

  computed: {
    nextIndex() {
      return modulo(this.currentIndex + 1, this.slides.length)
    },

    previousIndex() {
      return modulo(this.currentIndex - 1, this.slides.length)
    },

    dummyIndex() {
      return modulo(this.currentIndex + 2, this.slides.length)
    },

    slides() {
      if (this.items.length === 0) return null
      if (this.items.length >= MIN_ITEM_COUNT) return this.items
      return [...this.items, ...this.items]
    },

    hasSlides() {
      return this.slides && this.slides.length
    }
  },

  watch: {
    currentIndex(value) {
      if (this.pushRouteTimeout) clearTimeout(this.pushRouteTimeout)

      this.pushRouteTimeout = setTimeout(() => {
        this.$router.push(normalizeUrl(this.slides[value].url));
      }, 1)

      this.$router.push(this.slides[value].url)
    },

    slides(value) {
      this.$nextTick(() => { this.updateCurrentFromUrl() })
    },
  },

  mounted() {
    this.$nextTick(() => { this.updateCurrentFromUrl() })
    this.showItems = true
  },

  beforeDestroy() {
    if (this.pushRouteTimeout) clearTimeout(this.pushRouteTimeout)
  },

  methods: {
    updateCurrentFromUrl() {
      if (!this.hasSlides) return

      const routeMatch = (route) => normalizeUrl(route) === normalizeUrl(this.$route.path);

      if (routeMatch(this.slides[this.currentIndex]?.url)) return false

      if (routeMatch(this.slides[this.nextIndex]?.url)) {
        this.currentIndex = this.nextIndex
      } else if (routeMatch(this.slides[this.previousIndex]?.url)) {
        this.currentIndex = this.previousIndex
      } else {
        const index = this.items.findIndex(item => routeMatch(item.url))
        this.currentIndex = Math.max(index, 0)
      }
    },

    getClassName(index) {
      switch (index) {
        case this.currentIndex:
          return 'popin-navigation__item--active'
        case this.nextIndex:
          return 'popin-navigation__item--next'
        case this.previousIndex:
          return 'popin-navigation__item--previous'
        default:
          return 'popin-navigation__item--disabled'
      }
    },

    previous() {
      this.currentIndex = modulo(this.currentIndex - 1, this.slides.length)
      this.$router.push(normalizeUrl(this.slides[this.currentIndex].url));
    },

    next() {
      this.currentIndex = modulo(this.currentIndex + 1, this.slides.length)
      this.$router.push(normalizeUrl(this.slides[this.currentIndex].url));
    },
  },
}
