import { parseDataImage } from './dataMedia'

const PUBLIC_HOST = process.env.PUBLIC_HOST

export class MetaObj {
  constructor(title, description, image = {}, slug = "") {
    this.metaTitle = title
    this.metaDescription = description
    this.metaImage = image
    this.slug = slug
  }
}

export function parseDataMetaTags(data, defaultData = {}, slug = "") {
  const metaData = data?.content?.seo || data?.seo || data || defaultData

  if (!metaData) return

  let {
    metaTitle: title = defaultData.metaTitle,
    metaDescription: description = defaultData.metaDescription,
    metaImage = defaultData.metaImage,
    canonicalURL: canonical = defaultData.canonicalURL,
    metaSocial = []
  } = metaData

  // Trouver l'élément spécifique à Facebook dans metaSocial
  //const facebookData = metaSocial.find(item => item.socialNetwork === "Facebook");

  // Utiliser la description et l'image de Facebook si disponible
  /*if (facebookData) {
    description = facebookData.description || description;
    if (facebookData.image && facebookData.image.data) {
      metaImage = facebookData.image.data.attributes || metaImage;
    }
  }*/

  title = title + " | My Beers"
  const ogDescription = description
  const ogUrl = PUBLIC_HOST + (canonical ?? slug)

  const imgData = metaImage?.data?.attributes || metaImage
  const imgObj = parseDataImage(imgData)
  const ogImage = (imgObj.medium || imgObj.large || imgObj.origin || {}).url

  return {
    title,
    meta: [
      { hid: 'title', name: 'title', content: title},
      { hid: 'description', name: 'description', content: description },
      { hid: 'og:title', property: 'og:title', content: title },
      {
        hid: 'og:description',
        property: 'og:description',
        content: ogDescription,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: ogImage,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: ogUrl,
      },
      { hid: 'twitter:title', name: 'twitter:title', content: title },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: ogDescription,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: ogImage,
      },
      { hid: 'twitter:site', name: 'twitter:site', content: ogUrl },
    ],
    link: [
      { hid: 'canonical', rel: 'canonical', href: ogUrl }
    ]
  }
}
